import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import TomSelect from "tom-select";
import { TomOption, TomInput } from "tom-select/dist/cjs/types";
import { loadingIcon } from "../helpers";

interface Tag {
  id: string;
  name: string;
  colorBg: string;
  colorFg: string;
  tagGroupId: string;
  tagGroupName: string;
  tagGroupType: string;
}

// Connects to data-controller="tag-select"
export default class extends Controller<HTMLSelectElement> {
  static values = {
    classes: Array,
    itemClasses: Array,
    allowDuplicateTagGroup: Boolean,
    includeUntagged: {
      type: Boolean,
      default: false,
    },
    lock: Boolean,
    disabled: Boolean,
    tagUrl: String,
    id: String,
  };

  static targets = ["selectElement"];

  declare select: TomSelect;
  declare hasClassesValue: boolean;
  declare hasItemClassesValue: boolean;
  declare classesValue: string[];
  declare itemClassesValue: string[];
  declare allowDuplicateTagGroupValue: boolean;
  declare includeUntaggedValue: boolean;
  declare lockValue: boolean;
  declare disabledValue: boolean;
  declare tagUrlValue: string;
  declare hasTagUrlValue: boolean;
  declare idValue: string;
  declare hasIdValue: string;

  declare selectElementTarget: HTMLSelectElement;

  declare templateElements: Array<HTMLTemplateElement>;

  connect() {
    let plugins = ["remove_button"];

    if (this.lockValue) {
      plugins = [];
    }

    this.templateElements = Array.from(this.element.querySelectorAll("template"));

    const options = {
      plugins: plugins,
      preload: "focus",
      load: this.loadOptions.bind(this),
      score: this.scoreSearchResults,
      maxOptions: 100,
      hidePlaceholder: true,
      valueField: "id",
      labelField: "name",
      searchField: ["name"],
      optgroupField: "tagGroupId",
      optgroupLabelField: "tagGroupName",
      optgroupValueField: "tagGroupId",
      onBlur: () => {
        // We want the options to reload when the user focuses on the select
        this.select.wrapper.classList.remove("preloaded");
        this.select.clearOptions();
        this.select.settings.load = this.loadOptions.bind(this);
      },
      render: {
        option: (item: Tag, escape: (value: string) => string) => {
          if (item.id === "untagged") {
            return this.buildUntaggedOption(item, escape);
          } else if (item.id.startsWith("not_tagged_with_")) {
            return `<span class="hidden"></span>`; // Don't render, this is a ghost option
          } else {
            return this.buildTagOption(item, escape);
          }
        },
        item: (item: Tag, escape: (value: string) => string) => {
          if (item.id === "untagged" || item.id.startsWith("not_tagged_with_")) {
            return this.buildUntaggedItem(item, escape);
          } else {
            return this.buildTagItem(item, escape);
          }
        },
        optgroup_header: (data: Tag, escape: (value: string) => string) => {
          if (data.tagGroupId === "untagged") {
            return "";
          }
          const optGroupHeader = this.tempEl("optgroupHeader");
          optGroupHeader.firstElementChild.textContent = this.decodeHtmlEntities(escape(data.tagGroupName));
          optGroupHeader.append(this.buildNoGroupButton(data));
          optGroupHeader.append(this.buildNewButton(data));
          return optGroupHeader;
        },
        loading: (_data, _escape) => {
          return `
            <div class="p-1 flex flex-row gap-2 items-center">
              ${loadingIcon()}
              <span>Loading...</span>
            </div>
          `;
        },
      },
      onChange: (_values: string[]) => {
        this.updateDisabledStates();
      },
      onItemAdd: (values: string[]) => {
        this.select.setTextboxValue("");
        this.select.refreshOptions();
        this.toggleUntaggedSelection(values);
      },
      onInitialize: () => {
        if (this.allowAddNewButton()) {
          const dropdown = this.selectElementTarget.nextElementSibling.querySelector(".ts-dropdown");

          dropdown.appendChild(this.tempEl("fullNewButton"));
        }
      },
    };

    if (this.allowMultipleTagsPerGroup) {
      options["onChange"] = null;
    }

    this.select = new TomSelect(this.selectElementTarget as TomInput, options);

    if (!this.allowMultipleTagsPerGroup) {
      this.updateDisabledStates();
    }

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        this.select.control.classList.add(className);
      });
    }

    if (this.lockValue) {
      this.select.lock();
    }

    if (this.disabledValue) {
      this.disableSelect();
    }
  }

  selectOption(optionId: string) {
    this.select.addItem(optionId);
  }

  tempEl(templateName) {
    const template = this.templateElements.find((element) => {
      if (element.dataset.templateName === templateName) {
        return element;
      }
    });

    return template.content.firstElementChild.cloneNode(true) as HTMLElement;
  }

  allowAddNewButton() {
    const whitelist = [
      new RegExp("/ledger_management/journal_entries/new"),
      new RegExp("/ledger_management/ledger_transactions/lt_(.*)/edit$"),
      new RegExp("/ledger_management/ledger_transactions/lt_(.*)/duplicate$"),
      new RegExp("/ledger_management/ledger_transactions/lt_(.*)/reverse$"),
      new RegExp("/ledger_management/multi_ledger_transactions/mlt_(.*)/edit$"),
      new RegExp("/account_reconciliation/connected_transactions"),
      new RegExp("/payables_management(.*)"),
    ];

    for (const regex of whitelist) {
      if (regex.test(window.location.href)) {
        return true;
      }
    }

    return false;
  }

  buildNoGroupButton(data) {
    if (!this.includeUntaggedValue) {
      return "";
    }

    const iconNoTagGroupButton = this.tempEl("iconNoTagGroupButton");
    const innerButton = iconNoTagGroupButton.firstElementChild as HTMLElement;

    innerButton.setAttribute("data-action", "click->tag-select#addNoGroupTag");
    innerButton.setAttribute("data-tag-group-id", data.tagGroupId);

    return iconNoTagGroupButton;
  }

  addNoGroupTag(event) {
    const tagGroupId = event.currentTarget.dataset.tagGroupId;
    this.select.addItem(`not_tagged_with_${tagGroupId}`);

    // remove any other tags from the tag group
    for (const value in this.select.options) {
      const tag = this.select.options[value];
      const tagInTagGroup = tag.tagGroupId === tagGroupId && tag.id !== `not_tagged_with_${tagGroupId}`;
      if (tagInTagGroup || tag.id === "untagged") {
        this.select.removeItem(tag.id);
      }
    }
  }

  buildNewButton(data) {
    if (!this.allowAddNewButton() || data.tagGroupType !== "user") {
      return "";
    }

    const iconNewButton = this.tempEl("iconNewButton");
    const innerButton = iconNewButton.firstElementChild as HTMLElement;
    const card = JSON.parse(innerButton.dataset.card);
    const cardTurboSrcUrl = new URL(card.turboSrc, window.location.origin);
    cardTurboSrcUrl.searchParams.append("tag_group_id", data.tagGroupId);

    card.turboSrc = cardTurboSrcUrl.pathname + cardTurboSrcUrl.search;
    card.turboId = `tag_form_with_group_tag_${data.tagGroupId}_tag`;

    innerButton.dataset.card = JSON.stringify(card);

    return iconNewButton;
  }

  handleIdEnabledEvent(event) {
    const isEnabled = event.detail.enabled;
    const id = event.detail.id;

    if (this.hasIdValue && this.idValue === id) {
      if (isEnabled) {
        this.enableSelect();
      } else {
        this.disableSelect();
      }
    }
  }

  handleToggleEvent(event) {
    const isChecked = event.detail.checked;

    if (isChecked) {
      this.disableSelect();
    } else {
      this.enableSelect();
    }
  }

  enableSelect() {
    this.select.enable();
  }

  disableSelect() {
    this.select.disable();
    this.select.clear();
  }

  updateDisabledStates() {
    if (this.allowMultipleTagsPerGroup) {
      return;
    }
    const selectedTagIds = this.select.items;
    const selectedTagGroupIds = selectedTagIds.map((id) => this.select.options[id].tagGroupId);

    this.disableOptionsForGroupIds(selectedTagGroupIds);
  }

  toggleUntaggedSelection(values: string[]) {
    const isUntaggedSelected = values.includes("untagged");

    if (isUntaggedSelected) {
      for (const value in this.select.options) {
        const tag = this.select.options[value];
        if (tag.id !== "untagged") {
          this.select.removeItem(tag.id);
        }
      }
    } else {
      this.select.removeOption("untagged");
    }

    // get all tag group ids from the selected tags
    let notTaggedWithTagGroupIds: string[] = [];
    if (Array.isArray(values)) {
      const notTaggedWithTagGroupIds = [];
      values.map((id) => {
        if (!id.startsWith("not_tagged_with_")) {
          notTaggedWithTagGroupIds.push(`not_tagged_with_${this.select.options[id].tagGroupId}`);
        }
      });
    } else {
      if (!(values as string).startsWith("not_tagged_with_")) {
        notTaggedWithTagGroupIds = [`not_tagged_with_${this.select.options[values].tagGroupId}`];
      }
    }

    // we want to remove any pre-existing not_tagged_with options if we select a tag
    // with a tag group id that matches the not_tagged_with option
    for (const value in this.select.options) {
      const tag = this.select.options[value];
      if (notTaggedWithTagGroupIds.includes(tag.id)) {
        this.select.removeItem(tag.id);
      }
    }
  }

  disableOptionsForGroupIds(groupIds: string[]) {
    for (const value in this.select.options) {
      const data = this.select.options[value];
      const wasDisabled = data.disabled;
      data.disabled = groupIds.includes(data.tagGroupId);

      if (wasDisabled != data.disabled) {
        this.select.updateOption(value, data);
      }
    }
  }

  disconnect() {
    this.select.destroy();
  }

  // Build out the optgroup data structure for TomSelect
  buildOptGroups(tags: Tag[]): TomOption[] {
    const tagGroups = tags.reduce((groups, tag) => {
      if (!groups[tag.tagGroupId]) {
        groups[tag.tagGroupId] = {
          tagGroupName: tag.tagGroupName,
          tagGroupId: tag.tagGroupId,
          tagGroupType: tag.tagGroupType,
        };
      }
      return groups;
    }, {});

    if (this.includeUntaggedValue) {
      tagGroups[0] = this.untaggedTagGroupDefinition();
    }

    return Object.values(tagGroups);
  }

  loadOptions(_query: string, callback: (tags: Tag[] | void, optionGroups: TomOption[] | void) => void) {
    if (this.select.loading > 1) {
      callback();
      return;
    }

    get(this.tagUrlValue, { responseKind: "json" })
      .then((response: Response) => response.json)
      .then((payload: Tag[]) => {
        const optionGroups = this.buildOptGroups(payload);

        if (this.includeUntaggedValue) {
          // Add "Untagged" to the beginning of the list
          payload.unshift(this.untaggedTagDefinition());

          // for each optionGroup, build an option for not having that tag group
          for (const optionGroup of optionGroups) {
            payload.push(this.notTaggedWithTagGroupDefinition(optionGroup));
          }
        }

        callback(payload, optionGroups);

        if (!this.allowMultipleTagsPerGroup) {
          this.updateDisabledStates();
        }

        // We only want to load the data once, so we set the load function to null
        this.select.settings.load = null;
      })
      .catch((error) => {
        console.error(error);

        callback();
      });
  }

  scoreSearchResults(search) {
    return (item) => {
      let score = 0;
      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseTagGroupName = item.tagGroupName.toLowerCase();
      const lowerCaseSearch = search.toLowerCase();

      if (lowerCaseName === lowerCaseSearch) {
        score = 1;
      } else if (lowerCaseName.includes(lowerCaseSearch)) {
        score = 0.75;
      } else if (lowerCaseTagGroupName === lowerCaseSearch) {
        score = 0.5;
      } else if (lowerCaseTagGroupName.includes(lowerCaseSearch)) {
        score = 0.25;
      }

      return score;
    };
  }

  get allowMultipleTagsPerGroup() {
    return this.allowDuplicateTagGroupValue;
  }

  buildUntaggedOption(item, escape: (value: string) => string) {
    const style = `style="background-color: ${item.colorBg}; color: ${item.colorFg};"`;
    return `
      <div class="tag-selector inline-flex items-center m-1 px-2 py-1 text-xs font-medium border rounded-lg" ${style}>
        <span>
          ${escape(item.name)}
        </span>
      </div>
    `;
  }

  buildTagOption(item, escape: (value: string) => string) {
    const style = `style="background-color: ${item.colorBg}; color: ${item.colorFg};"`;
    return `
      <div class="tag-selector inline-flex items-center m-1 px-2 py-1 text-xs font-medium border border-transparent rounded" ${style}>
        <span>
          ${escape(item.name)}
        </span>
      </div>
    `;
  }

  buildUntaggedItem(item, escape: (value: string) => string) {
    const style = `style="background-color: ${item.colorBg}; color: ${item.colorFg};"`;

    return `
      <div class="text-xs font-medium rounded !border !border-gray-300" ${style}>
        ${escape(item.name)}
      </div>
    `;
  }

  buildTagItem(item, escape: (value: string) => string) {
    const style = `style="background-color: ${item.colorBg}; color: ${item.colorFg};"`;
    let itemClasses = "";
    if (this.hasItemClassesValue) {
      itemClasses = this.itemClassesValue.join(" ");
    }

    return `
      <div class="tag-selector text-xs font-medium rounded ${itemClasses}" ${style}>
        ${escape(item.tagGroupName + ": " + item.name)}
      </div>
    `;
  }

  untaggedTagGroupDefinition() {
    return {
      tagGroupName: "Untagged",
      tagGroupId: "untagged",
      tagGroupType: "system",
    };
  }

  untaggedTagDefinition() {
    return {
      id: "untagged",
      name: "Untagged",
      colorBg: "#ffffff",
      colorFg: "#000000",
      tagGroupId: "untagged",
      tagGroupName: "Untagged",
      tagGroupType: "system",
    };
  }

  notTaggedWithTagGroupDefinition(optionGroup) {
    return {
      id: `not_tagged_with_${optionGroup.tagGroupId}`,
      name: `Not Tagged w/ ${optionGroup.tagGroupName}`,
      colorBg: "#ffffff",
      colorFg: "#000000",
      tagGroupId: optionGroup.tagGroupId,
      tagGroupName: optionGroup.tagGroupName,
      tagGroupType: optionGroup.tagGroupType,
    };
  }

  // creates a temporary <div>, assigns the encoded string to its innerHTML, and retrieves
  // the decoded string from textContent to display special characters properly
  decodeHtmlEntities(encodedString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = encodedString;
    return tempDiv.textContent || tempDiv.innerText || "";
  }
}
