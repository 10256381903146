import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-amount"
export default class extends Controller {
  static targets = ["sourceAmountField", "targetAmountField"]

  declare sourceAmountFieldTarget: HTMLInputElement;
  declare targetAmountFieldTarget: HTMLInputElement;
  declare hasSourceAmountFieldTarget: boolean;
  declare hasTargetAmountFieldTarget: boolean;

  connect() {
    this.updateAmount();
  }

  updateAmount() {
    if (this.hasSourceAmountFieldTarget && this.hasTargetAmountFieldTarget) {
      this.targetAmountFieldTarget.value = this.sourceAmountFieldTarget.value;
    }
  }
}
