import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare allSelectedValue: string;
  declare selectAllTargets: HTMLInputElement[];
  declare radioTargets: HTMLInputElement[];

  static targets = ["selectAll", "radio"];
  static values = {
    allSelected: String
  };

  allSelectedValueChanged() {
    this.selectAllTargets.forEach((radio) => {
      if (radio.value == this.allSelectedValue) {
        radio.checked = true;
      } else {
        radio.checked = false;
      }
    });

    this.radioTargets.forEach((radio) => {
      if (radio.value == this.allSelectedValue) {
        radio.checked = true;
      }
    });
  }

  allMatchingRadioTargetsAreSelected(value) {
    return this.radioTargets.filter(radio => radio.value == value).every(radio => radio.checked);
  }

  selectAll(event) {
    this.allSelectedValue = event.target.value;
  }

  updateSelectAllTargets(event) {
    if (this.allMatchingRadioTargetsAreSelected(event.target.value)) {
      this.allSelectedValue = event.target.value;
    } else {
      this.allSelectedValue = "";
    }
  }
}
