import { Controller } from "@hotwired/stimulus"

interface HTMLAccountElement extends HTMLElement {
  textContent: string
  dataset: {
    accountId: string
  }
}

interface Match {
  start: number
  end: number
  text: string
}

export default class extends Controller {
  static targets = ["searchInput", "accountItem", "noResults"]

  declare searchInputTarget: HTMLInputElement
  declare accountItemTargets: HTMLAccountElement[]
  declare noResultsTarget: HTMLElement

  focusSearch() {
    this.searchInputTarget.focus()
  }

  submitFirstResult(event: KeyboardEvent) {
    event.preventDefault()

    const visibleItems = this.accountItemTargets.filter(
      item => item.style.display !== 'none'
    )

    if (visibleItems.length > 0) {
      const firstItem = visibleItems[0]
      const submitButton = firstItem.querySelector('button[type="submit"]')
      if (submitButton instanceof HTMLButtonElement) {
        submitButton.click();
      }
    }
  }

  handleEscape() {
    // Clear the search input
    this.searchInputTarget.value = '';
    this.filterAccounts();

    // Unfocus the input
    this.searchInputTarget.blur();

    // Dispatch event to close dropdown
    this.dispatch("closeMenu");
  }

  filterAccounts() {
    const searchTerms = this.getSearchTerms()
    let hasVisibleItems = false

    this.accountItemTargets.forEach(item => {
      // Store original text for matching
      const originalText = item.textContent?.trim() || ""
      const matches = this.textMatchesAllTerms(originalText, searchTerms)

      this.updateItemVisibility(item, matches)
      if (matches) hasVisibleItems = true

      const accountTextElement = item.querySelector('.account-text')

      if (matches && searchTerms.length > 0) {
        accountTextElement.innerHTML = this.getHighlightedHtml(originalText, searchTerms)
      } else {
        accountTextElement.textContent = originalText
      }
    })

    this.noResultsTarget.style.display = hasVisibleItems ? "none" : "block"
  }

  private getSearchTerms(): string[] {
    return this.searchInputTarget.value
      .toLowerCase()
      .split(' ')
      .filter(term => term.length > 0)
  }

  private textMatchesAllTerms(text: string, searchTerms: string[]): boolean {
    return searchTerms.every(term => text.toLowerCase().includes(term))
  }

  private updateItemVisibility(item: HTMLElement, isVisible: boolean) {
    item.style.display = isVisible ? "" : "none"
  }

  private getHighlightedHtml(text: string, searchTerms: string[]): string {
    const matches = this.findAllMatches(text, searchTerms)
    const mergedMatches = this.mergeOverlappingMatches(matches)
    return this.buildHighlightedHtml(text, mergedMatches)
  }

  private findAllMatches(text: string, searchTerms: string[]): Match[] {
    const matches: Match[] = []
    searchTerms.forEach(term => {
      const regex = new RegExp(this.escapeRegExp(term), 'gi')
      let match
      while ((match = regex.exec(text)) !== null) {
        matches.push({
          start: match.index,
          end: match.index + match[0].length,
          text: match[0]
        })
      }
    })
    return matches
  }

  /**
   * Merges overlapping text matches to prevent nested or overlapping highlights.
   * For example, if we have matches for "test" and "testing" in "testing123",
   * instead of having two separate matches at [0-4] and [0-7], we merge them
   * into a single match at [0-7].
   *
   * @param matches - Array of Match objects containing start/end positions
   * @returns Array of merged Match objects with no overlapping ranges
   *
   * Example:
   * Input: [{start: 0, end: 4}, {start: 0, end: 7}, {start: 10, end: 12}]
   * Output: [{start: 0, end: 7}, {start: 10, end: 12}]
   */
  private mergeOverlappingMatches(matches: Match[]): Match[] {
    if (matches.length === 0) return []

    const sortedMatches = [...matches].sort((a, b) => a.start - b.start)
    return sortedMatches.reduce((acc: Match[], curr) => {
      if (acc.length === 0) {
        acc.push(curr)
        return acc
      }

      const prev = acc[acc.length - 1]
      if (curr.start <= prev.end) {
        prev.end = Math.max(prev.end, curr.end)
      } else {
        acc.push(curr)
      }
      return acc
    }, [])
  }

  private buildHighlightedHtml(text: string, matches: Match[]): string {
    let result = ''
    let lastIndex = 0

    matches.forEach(match => {
      // Add text before match
      result += this.escapeHtml(text.substring(lastIndex, match.start))
      // Add highlighted match
      result += `<span class="bg-yellow-200">${this.escapeHtml(text.substring(match.start, match.end))}</span>`
      lastIndex = match.end
    })
    // Add remaining text
    result += this.escapeHtml(text.substring(lastIndex))

    return result
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  private escapeHtml(text: string): string {
    const div = document.createElement('div')
    div.textContent = text
    return div.innerHTML
  }
}