import StimulusFlatpickr from "stimulus-flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import { Options } from "flatpickr/dist/types/options";
import { parseDateRangeOption } from "../helpers/date_range_option_parser";
import { TomInput } from "tom-select/dist/cjs/types";

type DispatchOptions = Partial<{
  target: Element | Window | Document;
  detail: object;
  prefix: string;
  bubbles: boolean;
  cancelable: boolean;
}>;

export default class extends StimulusFlatpickr {
  declare config: Options;
  declare dispatch: (eventName: string, options?: DispatchOptions) => CustomEvent;
  declare fp: Instance;
  declare startDateTarget: HTMLInputElement;
  declare endDateTarget: HTMLInputElement;
  declare dateRangeTarget: TomInput;
  declare intervalTarget: HTMLSelectElement;
  declare compareableTarget: HTMLInputElement;

  static targets = ["startDate", "endDate", "dateRange", "interval", "compareable"];

  initialize() {
    this.config = {
      mode: "range",
      showMonths: 2,
      clickOpens: false,
      dateFormat: "m/d/Y",
    };
  }

  toDate(string) {
    return string.split("T")[0];
  }

  // Hook fired when calendar is closed
  close(selectedDates, dateStr, _instance) {
    if (selectedDates.length === 2) {
      this.startDateTarget.value = this.toDate(selectedDates[0].toISOString());
      this.endDateTarget.value = this.toDate(selectedDates[1].toISOString());
      this.dateRangeTarget.tomselect.updateOption("SelectedDateRange", {
        value: "SelectedDateRange",
        text: dateStr,
        optgroup: 1,
        disabled: false,
        hidden: true,
      });
      this.dateRangeTarget.tomselect.setValue("SelectedDateRange");
      this.dateRangeTarget.tomselect.refreshOptions(false);

      const formElement = document.getElementById("filters-form");

      if (formElement) {
        const event = new Event("submit", { bubbles: true });
        // Submitting the event from the filters-form element
        formElement.dispatchEvent(event);
      } else {
        this.dispatch("submit");
      }
    } else if (this.dateRangeTarget.tomselect.getValue() === "NewDateRange") {
      this.clearStartAndEndDates();
    }
  }

  // Action invoked when tomselect option is selected
  input({ target: { value } }) {
    if (!value) {
      return;
    } else if (value === "SelectedDateRange") {
      return;
    } else if (value === "NewDateRange") {
      this.fp.clear();

      const currentMonth = new Date().getMonth();
      this.fp.changeMonth(currentMonth - 1, false); // Display the 2-month calendar spread starting with the month prior to the current month
      const currentYear = new Date().getFullYear();
      this.fp.changeYear(currentYear);

      this.fp.open();
    } else {
      const { startDate, endDate, comparable } = parseDateRangeOption(value);

      this.startDateTarget.value = startDate == null ? "" : this.toDate(startDate.toISOString());
      this.endDateTarget.value = endDate == null ? "" : this.toDate(endDate.toISOString());
      this.compareableTarget.value = comparable;

      this.dispatch("submit");
    }
  }

  clearStartAndEndDates() {
    this.dateRangeTarget.tomselect.updateOption("SelectedDateRange", {
      value: "SelectedDateRange",
      text: "No Selected Range",
      optgroup: 1,
      disabled: true,
    });
    this.dateRangeTarget.tomselect.clear();
    this.dateRangeTarget.tomselect.refreshOptions(false);

    this.startDateTarget.value = null;
    this.endDateTarget.value = null;
  }
}
