import {
  ICellRendererComp,
  ICellRendererParams,
  IHeaderGroupComp,
  IHeaderGroupParams,
  RowNode,
} from "ag-grid-enterprise";
import { ITag, ITagGroup, IAccountCode, IAccountCodeGroup, IFlattenedTagOrAccountCode } from "../../types";
import { isGroupHeader } from "./node_helpers";

const accountCodeStyledCell = (value: string, bgColor: string, fgColor: string) => {
  return `
    <div class="inline-flex items-center px-2 py-1 text-xs border border-transparent rounded-lg font-semibold focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      style="background-color: ${bgColor}; color: ${fgColor}">
      ${value}
    </div>
  `;
};

const tagStyledCell = (value: string, bgColor: string, fgColor: string) => {
  let extraClasses = "";
  if (value === "Untagged") {
    bgColor = "#e6e6e8";
    fgColor = "#6A6A6A";
    extraClasses = "italic";
  }
  return `
    <div class="inline-flex items-center px-2 py-1 text-xs font-medium border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${extraClasses}"
      style="background-color: ${bgColor}; color: ${fgColor}">
      ${value}
    </div>
  `;
};

export class FlattenedAccountCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div");

    let metadata = params.data?.metadata;
    if (!metadata && params.context) {
      metadata = params.context.metadata;
    }

    const accountCode: IAccountCode | undefined = metadata?.accountCode;
    const accountCodeGroup: IAccountCodeGroup | undefined = metadata?.accountCodeGroup;

    let accountCodeName = "";
    let template = "";
    const value = params.value ? params.value : params.node?.key;

    if (accountCode && accountCodeGroup) {
      if (accountCodeGroup.name === "Uncoded") {
        accountCodeName = accountCode.name;
        template = accountCodeStyledCell(accountCode.name, accountCode.accountCodeBg, accountCode.accountCodeFg);
      } else {
        accountCodeName = `${accountCodeGroup.name}: ${accountCode.name}`;
        template = accountCodeStyledCell(accountCodeName, accountCode.accountCodeBg, accountCode.accountCodeFg);
      }
    }

    // Remove the account code name from the value
    const accountName = value?.replace(`- ${accountCodeName}`, "");
    const accountSpan = `<span class="mr-2">${accountName}</span>`;

    if (params.data?.groupLink) {
      this.eGui.innerHTML = `<a href="${params.data.groupLink}" class="text-sumit-primary-black" target = "_blank">${accountSpan} ${template}</a>`;
    } else {
      this.eGui.innerHTML = `${accountSpan} ${template}`;
    }

    // Handle grand total case
    if (params.node.footer && params.node.level === -1) {
      this.eGui.innerHTML = `<span class="text-sumit-primary-black font-bold" style="">Grand Total</span>`;
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}

export class AccountCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div"); // create the cell

    let template: string;
    const value = params.value ? params.value : params.node?.key;

    let metadata = params.node?.data?.metadata;
    if (!metadata && params.context) {
      metadata = params.context.metadata;
    }

    if (metadata?.generated_total_row) {
      template = `<span class="text-sumit-primary-black font-bold" style="">${value}</span>`;
    } else if (params.node.footer) {
      const isRootLevel = params.node.level === -1;

      if (isRootLevel) {
        template = `<span class="text-sumit-primary-black font-bold" style="">Grand Total</span>`;
      } else {
        template = `<span class="text-sumit-primary-black font-bold" style="">Total ${value}</span>`;
      }
    } else {
      const tag: ITag | undefined = metadata?.tag;
      const accountCode: IAccountCode | undefined = metadata?.accountCode;

      if (tag) {
        template = tagStyledCell(tag.name, tag.tagBg, tag.tagFg);
      } else if (accountCode) {
        template = accountCodeStyledCell(accountCode.name, accountCode.accountCodeBg, accountCode.accountCodeFg);
      } else if (!params.data || params.node.allChildrenCount !== null) {
        // No data means this is a "filler" or "non-provided" row or has no children (which means it's a row group when using tree mode)
        let metadata = undefined;
        let tagGroup: ITagGroup | undefined = undefined;
        let accountCodeGroup: IAccountCodeGroup | undefined = undefined;

        params.node.childrenAfterFilter.forEach((child) => {
          tagGroup = child.data?.metadata?.tagGroup as ITagGroup;
          accountCodeGroup = child.data?.metadata?.accountCodeGroup as IAccountCodeGroup;

          if (child.data?.metadata) {
            metadata = child.data.metadata;
            return;
          }
        });

        if (metadata && value && tagGroup?.name === value) {
          template = tagStyledCell(metadata.tagGroup.name, metadata.tag.tagBg, metadata.tag.tagFg);
        } else if (metadata && value && accountCodeGroup?.name === value) {
          template = accountCodeStyledCell(
            metadata.accountCodeGroup.name,
            metadata.accountCode.accountCodeBg,
            metadata.accountCode.accountCodeFg,
          );
        } else {
          template = value;
        }
      }
    }

    if (template === undefined) {
      template = value;
    }

    template = params.data?.groupLink
      ? `<a href = "${params.data.groupLink}" class="text-sumit-primary-black" target = "_blank">${template}</a>`
      : template;

    this.eGui.innerHTML = template;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}

export class GLAccountCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div");

    let template: string;
    const value = params.value;

    if (value === undefined) {
      return;
    }

    if (params.node.footer) {
      const isRootLevel = params.node.level === -1;
      const isAccountLevel = params.node.level === 1;

      if (isRootLevel) {
        template = `<span class="text-sumit-primary-black font-bold" style="">Grand Total</span>`;
      } else if (isAccountLevel) {
        template = `<span class="text-sumit-primary-black font-bold" style="">Total ${value}</span>`;
      } else {
        template = `<span class="text-sumit-primary-black font-bold" style="">Total ${value}</span>`;
      }
    } else {
      template = `<span class="text-sumit-primary-black font-bold" style="">${value}</span>`;
    }

    if (template === undefined) {
      template = params.value;
    }

    this.eGui.innerHTML = template;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}

export class GLCellRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    // create the cell
    this.eGui = document.createElement("span");
    const value = params.value;
    const formattedValue = params.valueFormatted;
    const node = params.node as unknown as RowNode;

    if (params.node.level === 0) {
      this.eGui.innerHTML = "";
      return;
    }

    if ((isGroupHeader(node) && node.expanded) || value === null) {
      this.eGui.innerHTML = "";
      return;
    }

    if (value === undefined) {
      return;
    }

    const link = this.getLink(params);

    if (link) {
      this.eGui.innerHTML = `<a href="${link}" class="text-sumit-primary-black" target="_blank">${formattedValue}</a>`;
    } else {
      if (value === "No description") {
        this.eGui.innerHTML = `<span class="text-gray-400 font-normal italic" style="">${value}</span>`;
      } else if (formattedValue) {
        this.eGui.innerHTML = `<span class="text-sumit-primary-black font-normal" style="">${formattedValue}</span>`;
      } else {
        this.eGui.innerHTML = `<span class="text-sumit-primary-black font-normal" style="">${value}</span>`;
      }
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }

  getLink(params: ICellRendererParams): string | false {
    const linkField = `${params.colDef.field}_link`;

    if (params.data && params.data[linkField]) {
      return params.data[linkField];
    }

    if (params.colDef.colId == "total" && params.data && params.data.groupLink) {
      return params.data.groupLink;
    }

    return false;
  }
}

export class NumberCellRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    // create the cell
    this.eGui = document.createElement("span");
    const value = params.value;
    const formattedValue = params.valueFormatted;
    const node = params.node as unknown as RowNode;

    if (params.node?.data?.metadata?.generated_total_row) {
      if (value === null) {
        this.eGui.innerHTML = "";
      } else {
        this.eGui.innerHTML = `<span class="text-sumit-primary-black font-bold" style="">${formattedValue}</span>`;
      }
      return;
    }

    if ((isGroupHeader(node) && node.expanded) || value === null) {
      this.eGui.innerHTML = "";
      return;
    }

    if (value === undefined) {
      return;
    }

    const link = this.getLink(params);

    if (link) {
      this.eGui.innerHTML = `<a href="${link}" class="text-sumit-primary-black" target="_blank">${formattedValue}</a>`;
    } else {
      this.eGui.innerHTML = formattedValue;
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }

  getLink(params: ICellRendererParams): string | false {
    const linkField = `${params.colDef.field}_link`;

    if (params.data && params.data[linkField]) {
      return params.data[linkField];
    }

    if (params.colDef.colId == "total" && params.data && params.data.groupLink) {
      return params.data.groupLink;
    }

    return false;
  }
}

export function tagStyledHeader(value, bgColor, fgColor) {
  let extraClasses = "";
  if (value === "Untagged") {
    bgColor = "#c7c7c9";
    fgColor = "#4a4949";
    extraClasses = "italic";
  }
  return `
    <div class="px-2 py-1 text-xs font-medium rounded ${extraClasses}"
      style="background-color: ${bgColor}; color: ${fgColor}">
      ${value}
    </div>
  `;
}

export class HeaderGroupRenderer implements IHeaderGroupComp {
  eGui!: HTMLDivElement;

  // gets called once before the renderer is used
  init(params) {
    this.eGui = document.createElement("div");
    const colDef = params.columnGroup.getColGroupDef();

    let metadata = colDef?.metadata;
    if (!metadata && colDef?.context) {
      metadata = colDef?.context.metadata;
    }

    let content;
    if (metadata?.tag_id) {
      content = tagStyledHeader(params.displayName, metadata?.tag_color_bg, metadata?.tag_color_fg);
    } else {
      content = `<span class="text-black">${params.displayName}</span>`;
    }

    this.eGui.innerHTML = `
      <div class="customHeaderLabel">
        ${content}
      </div>
    `;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: IHeaderGroupParams) {
    return false;
  }
}

export function tagHeaderTemplate(colDef, isTextHeader = false) {
  let content;
  const metadata = colDef?.metadata;

  if ((metadata?.tag_id && !isTextHeader) || colDef.headerName === "Untagged") {
    content = tagStyledHeader(colDef.headerName, metadata?.tag_color_bg, metadata?.tag_color_fg);
  } else {
    content = `<span class="text-black text-xs">${colDef.headerName}</span>`;
  }

  return headerTemplate(content);
}

export function consolidatedHeaderTemplate(colDef) {
  let content;
  const metadata = colDef?.metadata;

  if (colDef?.metadata?.consolidationMetadata) {
    const spans = Object.entries(metadata?.consolidationMetadata).map(
      ([_key, consolidationMetadata]: [string, { text: string; value: string }]) => {
        return `<span class="whitespace-no-wrap text-xs font-normal">${consolidationMetadata.text}: ${consolidationMetadata.value}</span>`;
      },
    );

    content = `<span class="font-bold">${colDef.headerName}</span>
      <br><div class="flex mb-2"></div>${spans.join("<br>")}
    `;
  } else {
    content = `<span class="text-xs">${colDef.headerName}</span>`;
  }

  return headerTemplate(content);
}

export class AccountCodeCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div");

    const accountCode: IFlattenedTagOrAccountCode | undefined = params.data?.accountCode;

    let template = "";
    if (accountCode) {
      const accountCodeName = `${accountCode.group}: ${accountCode.name}`;
      template = accountCodeStyledCell(accountCodeName, accountCode.colorBg, accountCode.colorFg);
    }

    this.eGui.innerHTML = template;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}

export class TagCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div");
    const tags: IFlattenedTagOrAccountCode[] | undefined = params.data?.tags;

    let template = "";
    if (tags) {
      tags.forEach((tag) => {
        const tagName = `${tag.group}: ${tag.name}`;

        let colorBg = tag.colorBg;
        if (tag.colorBg === null) {
          colorBg = "#BDBDBD";
        }

        let colorFg = tag.colorFg;
        if (tag.colorFg === null) {
          colorFg = "#6A6A6A";
        }

        template += tagStyledCell(tagName, colorBg, colorFg);
      });
    }

    this.eGui.innerHTML = template;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}

export function headerTemplate(content) {
  return `
    <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon mr-2"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon mr-2"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
        <span class="ag-header-cell-text mr-2" role="columnheader">${content}</span>
        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
      </div>
    </div>
  `;
}
