import {
  ILoadingOverlayComp,
  ILoadingOverlayParams,
  INoRowsOverlayComp,
  INoRowsOverlayParams,
} from "ag-grid-community";

import { loadingIcon } from "..";

export class LoadingOverlay implements ILoadingOverlayComp {
  declare eGui: HTMLDivElement;

  init(_params: ILoadingOverlayParams) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `
        <div class="flex flex-row gap-2 items-center">
          ${loadingIcon()}
          <span>Loading...</span>
        </div>
      `;
  }

  getGui() {
    return this.eGui;
  }
}

export class NoRowsOverlay implements INoRowsOverlayComp {
  declare eGui: HTMLDivElement;

  init(_params: INoRowsOverlayParams) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `
        <div class="flex flex-col h-20">
          <span class="font-medium text-gray-800">There's no data here. Select options above and click "Run Report" to generate a report.</span>
        </div>
      `;
  }

  getGui() {
    return this.eGui;
  }
}
