import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reporting-row-options"
export default class extends Controller {
  static targets = ["tagsButton", "accountCodesButton", "tagsPanel", "accountCodesPanel"];

  declare tagsButtonTarget: HTMLButtonElement;
  declare accountCodesButtonTarget: HTMLButtonElement;
  declare tagsPanelTarget: HTMLDivElement;
  declare accountCodesPanelTarget: HTMLDivElement;

  static values = {
    visiblePanel: {
      type: String,
      default: "accountCodes",
    }
  }

  declare visiblePanelValue: string;

  connect() {
    // Ensure the panel is set to the default value when connected
    this.visiblePanelValueChanged(this.visiblePanelValue);
  }

  togglePanel(event) {
    this.visiblePanelValue = event.params.panel
  }

  visiblePanelValueChanged(newValue: string) {
    if (newValue === "accountCodes") {
      this.showAccountCodes();
    } else if (newValue === "tags") {
      this.showTags();
    }
  }

  showTags() {
    this.resetCheckboxes(this.accountCodesPanelTarget);
    this.updateButtonStyles(this.tagsButtonTarget, this.accountCodesButtonTarget);
    this.togglePanels(this.tagsPanelTarget, this.accountCodesPanelTarget);
  }

  showAccountCodes() {
    this.resetCheckboxes(this.tagsPanelTarget);
    this.updateButtonStyles(this.accountCodesButtonTarget, this.tagsButtonTarget);
    this.togglePanels(this.accountCodesPanelTarget, this.tagsPanelTarget);
  }

  resetCheckboxes(panel: HTMLElement) {
    const checkboxes = panel.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach((checkbox: HTMLInputElement) => { checkbox.checked = false; });
  }

  updateButtonStyles(activeButton: HTMLButtonElement, inactiveButton: HTMLButtonElement) {
    inactiveButton.classList.remove("bg-cyan-600", "text-white");
    inactiveButton.classList.add("bg-white", "text-black", "btn-white");

    activeButton.classList.remove("bg-white", "text-black", "btn-white");
    activeButton.classList.add("bg-cyan-600", "text-white");
  }

  togglePanels(showPanel: HTMLElement, hidePanel: HTMLElement) {
    showPanel.classList.remove("hidden");
    hidePanel.classList.add("hidden");
  }
}
