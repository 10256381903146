import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]
  declare menuTarget: HTMLElement

  toggle(event: Event) {
    event.stopPropagation();
    this.menuTarget.classList.toggle("hidden");
  }

  hide(event: Event) {
    // Don't hide if clicking inside the dropdown menu
    if (this.menuTarget.contains(event.target as Node)) {
      return;
    }

    // Hide if clicking outside the dropdown menu
    this.menuTarget.classList.add("hidden");
  }

  forceHide() {
    this.menuTarget.classList.add("hidden");
  }
}