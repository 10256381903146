import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLFormElement> {
  static targets = ["form"];

  declare formTarget: HTMLFormElement;
  declare hasFormTarget: boolean;
  declare preventNextSubmit: boolean;

  submit() {
    if (this.preventNextSubmit) {
      this.preventNextSubmit = false;
      return;
    }

    if (this.hasFormTarget) {
      this.formTarget.requestSubmit();
    } else {
      this.element.requestSubmit();
    }
  }

  preventSubmit() {
    this.preventNextSubmit = true;
  }
}
