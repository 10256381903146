import { Controller } from "@hotwired/stimulus";
import { GridOptions, createGrid, GetRowIdParams, RowSelectedEvent, ToolPanelDef, GridApi } from "ag-grid-enterprise";
import { dataTypeDefinitions } from "../helpers/reports/formatters";

// Connects to data-controller="uploaded-transactions"
export default class extends Controller {
  declare transactionDataValue: Array<object>;
  declare hasTransactionDataValue: boolean;

  declare gridTarget: HTMLDivElement;

  declare gridOptions: GridOptions;
  declare gridApi: GridApi;
  declare pageSize: number;

  static targets = ["grid"];

  static values = {
    transactionData: Array,
  };

  connect() {
    this.pageSize = 50;

    const defaultNumberColDef = {
      width: 180,
      cellDataType: "number",
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: [
          "equals",
          "notEqual",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
      },
      cellClass: ["ag-right-aligned-cell", "font-mono"],
      sortable: true,
    };

    this.gridOptions = {
      columnDefs: [
        {
          field: "date",
          width: 140,
          sortable: true,
          cellClass: "font-mono",
          filter: "agDateColumnFilter",
          filterParams: {
            maxNumConditions: 1,
          },
        },
        { field: "entity", width: 200 },
        { field: "account", width: 240 },
        { field: "description", sortable: true, minWidth: 120, flex: 1, filter: "agTextColumnFilter" },
        { field: "amount", headerName: "Amount", ...defaultNumberColDef },
        {
          field: "debits",
          headerTooltip: "This is the amount that will be shown as a debit when creating journal entries.",
          ...defaultNumberColDef,
        },
        {
          field: "credits",
          headerTooltip: "This is the amount that will be shown as a credit when creating journal entries.",
          ...defaultNumberColDef,
        },
      ],
      defaultColDef: {
        sortable: false,
      },
      getRowId: (params: GetRowIdParams) => params.data.id.toString(),
      statusBar: {
        statusPanels: [{ statusPanel: "agSelectedRowCountComponent" }, { statusPanel: "agAggregationComponent" }],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          } as ToolPanelDef,
          "filters",
        ],
      },
      dataTypeDefinitions: {
        ...dataTypeDefinitions,
      },
      allowContextMenuWithControlKey: true,
      tooltipShowDelay: 100,
      onRowSelected: (_event: RowSelectedEvent) => {
        // TODO: Implement
      },
    };

    this.gridOptions.rowData = this.getTransactionData();

    this.gridApi = createGrid(this.gridTarget, this.gridOptions);
  }

  getTransactionData() {
    return this.transactionDataValue;
  }

  disconnect() {
    this.gridApi.destroy();
  }
}
