import { ICellRendererParams } from "ag-grid-community";
import { IAccountCode } from "../../types/register";

export function accountCodeRenderer(params: ICellRendererParams & { template: HTMLTemplateElement }) {
  const accountCode: IAccountCode = params.value;

  // If the row is grouped and the account code is missing, don't render anything
  if (params.node.group && !accountCode) {
    return null;
  }

  if (accountCode && accountCode.id) {
    const template = document.importNode(params.template.content, true);
    const div = template.querySelector("div");

    div.textContent = accountCode.name;

    return div;
  } else {
    return `<span class="text-gray-400 italic">Uncoded</span>`;
  }
}

export function tagRenderer(params: ICellRendererParams & { template: HTMLTemplateElement }) {
  const tags = params.value || [];
  // Filtering out "Untagged" from the list here
  const realTags = tags.map((tag) => (tag.id ? tag : null)).filter(Boolean);
  const isGroupingByTag = params.colDef.colId === "ag-Grid-AutoColumn";

  // If the row is grouped, don't render anything
  if (params.node.group && !isGroupingByTag) {
    return null;
  }

  if (realTags && realTags.length > 0) {
    const parentEl = document.createElement("span");
    parentEl.classList.add("space-x-1");

    tags.forEach((tag) => {
      const template = document.importNode(params.template.content, true);
      const div = template.querySelector("div");

      div.textContent = tag.name || "Untagged";

      if (tag.color_bg) {
        div.style.backgroundColor = tag.color_bg;
        div.style.color = tag.color_fg;
      }

      parentEl.appendChild(div);
    });

    return parentEl;
  } else {
    return `<span class="text-gray-400 italic">Untagged</span>`;
  }
}
