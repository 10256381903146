import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["savingMessage", "savingText", "savingMessageIcon", "successMessageIcon"];
  declare savingMessageTarget: HTMLElement;
  declare savingTextTarget: HTMLElement;
  declare savingMessageIconTarget: HTMLElement;
  declare successMessageIconTarget: HTMLElement;

  handleFocusOut(_event) {
    this.showSavingMessage();
  }

  handleTurboFinish(event) {
    if (event.detail.success) {
      this.showSuccessMessage();
    } else {
      this.showErrorMessage();
    }
  }

  showSavingMessage() {
    this.savingMessageTarget.classList.remove("hidden");
    this.savingMessageIconTarget.classList.remove("hidden");
    this.successMessageIconTarget.classList.add("hidden");
    this.savingTextTarget.textContent = "Saving...";
  }

  hideSavingMessage() {
    this.savingMessageTarget.classList.add("hidden");
  }

  showSuccessMessage() {
    this.savingMessageIconTarget.classList.add("hidden");
    this.successMessageIconTarget.classList.remove("hidden");
    this.savingTextTarget.textContent = "Saved";

    setTimeout(() => {
      this.hideSavingMessage();
    }, 2000); // Hide success message after 2 seconds
  }

  showErrorMessage() {
    this.savingTextTarget.textContent = "Error Saving!";
    setTimeout(() => {
      this.hideSavingMessage();
    }, 2000); // Hide error message after 2 seconds
  }
} 
