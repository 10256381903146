import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unsaved-changes"
export default class extends Controller {
  declare observer: MutationObserver;
  declare noticeActivated: boolean;
  declare boundInputChangedFn: () => void;
  declare boundTurboBeforeVisitFn: (e) => void;
  declare boundBeforeUnloadFn: (e) => void;
  declare boundSubmitHandlerFn: () => void;
  declare turboBeforeVisitTriggered: boolean;
  declare formSubmitted: boolean;

  connect() {
    this.noticeActivated = false;
    this.formSubmitted = false;
    // this flag is here in order to prevent the beforeunload event from firing
    // if turbo event has fired. For some reason the "Register" link in the navbar triggers both events.
    this.turboBeforeVisitTriggered = false;

    this.boundInputChangedFn = this.inputChanged.bind(this);
    this.boundTurboBeforeVisitFn = this.turboBeforeVisit.bind(this);
    this.boundBeforeUnloadFn = this.beforeUnload.bind(this);
    this.boundSubmitHandlerFn = this.formSubmittedHandler.bind(this);

    this.observer = new MutationObserver(() => {
      const inputs = this.getInputs();
      inputs.forEach(input => {
        this.addInputChangedListeners(input);
      });

      const submits = this.getSubmitButtons();
      submits.forEach(input => {
        input.removeEventListener('click', this.boundSubmitHandlerFn);
        input.addEventListener('click', this.boundSubmitHandlerFn);
      });

    });

    const config = {
      childList: true,
      attributes: false,
      subtree: true,
    };

    this.observer.observe(document, config);
  }

  getInputs() {
    return this.element.querySelectorAll('input, select, textarea')
  }

  getSubmitButtons() {
    return this.element.querySelectorAll('button[type="submit"]');
  }

  formSubmittedHandler() {
    this.formSubmitted = true;
  }

  addInputChangedListeners(input) {
    input.addEventListener('input', this.boundInputChangedFn);
  }

  inputChanged() {
    this.turboBeforeVisitTriggered = false;
    this.activateNotice();
  }

  activateNotice() {
    if (this.noticeActivated) return;

    window.addEventListener('turbo:before-visit', this.boundTurboBeforeVisitFn)
    window.addEventListener('beforeunload', this.boundBeforeUnloadFn)
    this.noticeActivated = true;
    this.removeAllInputChangedListeners();
  }

  turboBeforeVisit(e) {
    if (this.formSubmitted) return;

    if (confirm("Are you sure you want to navigate away from this page? You will lose any unsaved changes.")) {
      this.turboBeforeVisitTriggered = true;
    } else {
      e.preventDefault();
      this.turboBeforeVisitTriggered = false;
    }
  }

  beforeUnload(e) {
    if (this.formSubmitted) return;

    if (this.turboBeforeVisitTriggered) {
      e.preventDefault();
      this.turboBeforeVisitTriggered = false;
      return;
    }
    e.preventDefault();
    e.returnValue = '';
  }

  removeAllSubmitButtonsListeners() {
    const submits = this.getSubmitButtons();
    submits.forEach(input => {
      input.removeEventListener('click', this.boundSubmitHandlerFn);
    });
  }

  removeAllInputChangedListeners() {
    const inputs = this.getInputs();
    inputs.forEach(input => {
      input.removeEventListener('input', this.boundInputChangedFn);
    });
  }

  disconnect() {
    this.observer.disconnect();
    window.removeEventListener('turbo:before-visit', this.boundTurboBeforeVisitFn)
    window.removeEventListener('beforeunload', this.boundBeforeUnloadFn)
    this.removeAllSubmitButtonsListeners();
    this.removeAllInputChangedListeners();
  }
}
