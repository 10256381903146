import { Controller } from "@hotwired/stimulus";
import { TomInput } from "tom-select/dist/cjs/types";
import { parseDateRangeOption } from "../helpers/date_range_option_parser";

// Connects to data-controller="reporting-datepicker"
export default class extends Controller<HTMLElement> {
  declare dateRangeTarget: TomInput;
  declare startDateTarget: HTMLInputElement;
  declare endDateTarget: HTMLInputElement;
  declare compareableTarget: HTMLInputElement;

  static targets = ["dateRange", "startDate", "endDate", "compareable"];

  clearDateRange() {
    this.dateRangeTarget.tomselect.clear();
  }

  clearStartAndEndDates() {
    this.startDateTarget.value = null;
    this.endDateTarget.value = null;
    this.startDateTarget.disabled = false;
  }

  input({ target: { value } }) {
    if (!value) {
      return;
    } else {
      const { startDate, endDate, comparable } = parseDateRangeOption(value);

      if (value === "since_inception") {
        this.startDateTarget.disabled = true;
      } else {
        this.startDateTarget.disabled = false;
      }

      this.startDateTarget.valueAsDate = startDate;
      this.endDateTarget.valueAsDate = endDate;
      this.compareableTarget.value = comparable;
    }
  }
}
