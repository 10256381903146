import { ReportContext } from "../types";
import { parseDateRangeOption } from "./date_range_option_parser";
import { TomInput } from "tom-select/dist/cjs/types";

export function prepareDateRangeOptions(options) {
  if (options?.date_range) {
    delete options.start_date;
    delete options.end_date;
  } else {
    delete options.date_range;
  }
  return options;
}

export function addRelativeDatesToParams(options) {
  if (options?.date_range) {
    const parsedDates = parseDateRangeOption(options.date_range);
    const startDate = parsedDates?.startDate?.toISOString()?.split("T")[0];
    const endDate = parsedDates?.endDate?.toISOString()?.split("T")[0];
    options.start_date = startDate;
    options.end_date = endDate;
  }
  return options;
}

export function getRelativeDates(context: ReportContext) {
  if (context.hasDateRangeTarget && context.dateRangeTarget.value !== "") {
    const parsedDates = parseDateRangeOption(context.dateRangeTarget.value);
    const startDate = parsedDates?.startDate?.toISOString()?.split("T")[0];
    const endDate = parsedDates?.endDate?.toISOString()?.split("T")[0];

    return {
      start_date: startDate,
      end_date: endDate,
    };
  }

  return {
    start_date: context.hasStartDateTarget ? context.startDateTarget.value : null,
    end_date: context.hasEndDateTarget ? context.endDateTarget.value : null,
  };
}

export function loadDateRange(values, context: ReportContext) {
  const dateRangeTomSelect = (context.dateRangeTarget as TomInput)?.tomselect;
  // this is here because when you load a report, click Run Report and then refresh the page, TomSelect doesn't load in time.
  if (dateRangeTomSelect) {
    dateRangeTomSelect.setValue(values.date_range);
  } else {
    context.dateRangeTarget.value = values.date_range;
  }
}

export function loadDateInputs(values, context: ReportContext) {
  if (context.hasDateRangeTarget && values.date_range) {
    loadDateRange(values, context);
    if (context.hasStartDateTarget) context.startDateTarget.value = getRelativeDates(context).start_date;
    if (context.hasEndDateTarget) context.endDateTarget.value = getRelativeDates(context).end_date;
  } else {
    if (context.hasStartDateTarget) context.startDateTarget.value = values.start_date;
    if (context.hasEndDateTarget) context.endDateTarget.value = values.end_date;
  }
}
