import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="counter"
export default class extends Controller {
  declare countToDecrementValue: number;
  declare countToIncrementValue: number;
  declare decrementTarget: HTMLElement;
  declare incrementTarget: HTMLElement;

  static targets = ['decrement', 'increment'];
  static values = {
    countToDecrement: {
      type: Number,
      default: 0
    },
    countToIncrement: {
      type: Number,
      default: 0
    }
  }

  countToDecrementValueChanged(value) {
    this.decrementTarget.textContent = value.toLocaleString();
  }

  countToIncrementValueChanged(value) {
    this.incrementTarget.textContent = value.toLocaleString();
  }

  decrement() {
    this.countToDecrementValue = Math.max(this.countToDecrementValue - 1, 0);
  }

  increment() {
    this.countToIncrementValue += 1;
  }
}
