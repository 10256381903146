import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare gridSettingsInputTarget: HTMLInputElement;
  declare periodInputTarget: HTMLInputElement;

  static targets = ["gridSettingsInput", "periodInput"]

  connect() {
    this.dispatch("connected");
  }

  updateGridState(event) {
    this.gridSettingsInputTarget.value = JSON.stringify(event.detail.gridState);
    this.periodInputTarget.value = event.detail.period;
  }
}
