import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="currency-subtotal"
export default class extends Controller {
  declare differenceTarget: HTMLElement;
  declare selectedAmountValue: number;
  declare hasLineItemTarget: boolean;
  declare lineItemTargets: HTMLInputElement[];
  declare submitButtonTarget: HTMLButtonElement;
  declare totalTarget: HTMLElement;
  declare totalValue: number;

  static targets = [
    "difference",
    "lineItem",
    "submitButton",
    "total"
  ];

  static values = {
    selectedAmount: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
  }

  totalValueChanged() {
    this.updateTotalText();
    this.updateDifferenceText();
    this.toggleSubmitButton();
  }

  differenceValue() {
    return Math.abs(this.selectedAmountValue) - this.totalValue;
  }

  toggleSubmitButton() {
    if (this.differenceValue() == 0) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", "disabled");
    }
  }

  updateDifferenceText() {
    if (this.differenceValue() == 0) {
      this.differenceTarget.textContent = "";
    } else if (this.differenceValue() > 0) {
      this.differenceTarget.textContent = `Missing: ${this.differenceValue().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
    } else {
      this.differenceTarget.textContent = `Excess: ${Math.abs(this.differenceValue()).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
    }
  }

  updateTotal() {
    if (this.hasLineItemTarget) {
      this.totalValue = this.lineItemTargets.reduce((total, element) => {
        return element.checked 
        ? total + parseFloat(element.dataset.currencySubtotalLineItemAmountValue) 
        : total;
      }, 0);
    }
  }

  updateTotalText() {
    this.totalTarget.textContent = this.totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}
